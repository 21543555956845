import React from "react";

// Components
import Container from "@material-ui/core/Container";
import SEO from "../components/SEO";
import KeyPoints from "../components/IndividualPage/KeyPoints";
import Features from "../components/IndividualPage/Features";
import Typography from "@material-ui/core/Typography";

// Styles
import { makeStyles } from "@material-ui/core/styles";
import ConvexSvg from "../components/ConvexSvg";

const styles = makeStyles((theme) => ({
  section1: {
    background: theme.gradients.gradientWhiteGrey,

    paddingBottom: theme.spacing(12),
  },
  intro: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
  },
  keypoints: {
    marginTop: theme.spacing(0.5),
  },
  section2: {
    background: "rgba(245,246,255,0.0001) !important",
  },
  featuresTitle: {
    marginTop: theme.spacing(12),
  },
}));

const IndexPage = (): JSX.Element => {
  const classes = styles();

  return (
    <>
      <SEO title="eKee" />
      <section className={classes.section1}>
        <Container maxWidth="md">
          <Typography variant="h4" align="center" className={classes.intro}>
            La manipulation des données impose une éthique rigoureuse
          </Typography>
          FIXME
        </Container>
      </section>
    </>
  );
};

export default IndexPage;
